import './style.css';
import {Map, View} from 'ol';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer.js';
import WMTS from 'ol/source/WMTS.js';
import WMTSTileGrid from 'ol/tilegrid/WMTS.js';
import {get as getProjection} from 'ol/proj.js';
import {getTopLeft, getWidth} from 'ol/extent.js';
import TileWMS from 'ol/source/TileWMS.js';
import Feature from 'ol/Feature.js';
import Point from 'ol/geom/Point.js';
import VectorSource from 'ol/source/Vector.js';
import {Icon, Style} from 'ol/style.js';

import config from './config.js'; 
import locationPin from './assets/img/location-pin.png'

const assets = import.meta.glob('/assets/img/*', {eager: true});
const projection = getProjection('EPSG:3857');
const projectionExtent = projection.getExtent();
const size = getWidth(projectionExtent) / 256;
const resolutions = new Array(19);
const matrixIds = new Array(19);

const queryUrl = "/query.php";
let isQuerying = false;
let queryXhr = null;
let marker = null;


// Generate resolutions and matrixIds arrays for WMTS
for (let z = 0; z < 19; ++z) {
  resolutions[z] = size / Math.pow(2, z);
  matrixIds[z] = z;
}

// Initialize map 
const map = new Map({
  target: 'map',
  layers: [],
  view: new View({
    center: [config.map.initialX, config.map.initialY],
    zoom: config.map.initialZoom,
    projection: projection,
    enableRotation: false, // disable rotation so that WMS GetFeatureInfo will work properly on mobile devices
  })
});

// Add basemaps
for (let i=0; i<config.layers.baseLayers.length; i++) {

  var baseLayer = new TileLayer({
    name: config.layers.baseLayers[i]['name'],
    source: new WMTS({
      preload: Infinity,
      url: config.layers.baseLayers[i]['url'],
      layer: config.layers.baseLayers[i]['name'],
      matrixSet: 'GoogleMapsCompatible',
      format: 'image/png',
      projection: projection,
      tileGrid: new WMTSTileGrid({
        origin: getTopLeft(projectionExtent),
        resolutions: resolutions,
        matrixIds: matrixIds,
      }),
      style: 'default',
      wrapX: true,
    }),
    visible: config.layers.baseLayers[i]['initialVisible']
  });

  map.addLayer(baseLayer)
}

$(document).ready(function() {

  // By default, Bootstrap will close any other offcanvas when an offcanvas is shown.
  // This will keep the left offcanvas open, even the user opens the offcanvas on the right
  $('nav [data-bs-toggle=offcanvas]').click(function() {
    if ($("#ig-left-offcanvas").hasClass("show")) {
      var leftOffcanvas = new bootstrap.Offcanvas($("#ig-left-offcanvas"));
      leftOffcanvas.show();
    }
  });
  
 // Read base layer list from config and build the base layer control
  var html = MakeBaseMapControl();
  $("#ig-basemap-select").append(html);
  $(".basemap-option-container img").on("click", OnBasemapSelected);
  
})

// Validate URL param, it must contains a map param with the correct map code, otherwise display an error message
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const mapParam = urlParams.get('map')

if (mapParam == "lcc") {

  // TODO load map data according to the map code

  // Add WMS layers (LGA, properties, and REM)
  for (let i=0; i<config.layers.wmsLayers.length; i++) {
    var wmsLayer = new TileLayer({
      name: config.layers.wmsLayers[i]['name'],
      source: new TileWMS({
        projection: projection,
        extent: projectionExtent,
        url: config.layers.wmsLayers[i]['url'],
        params: {'LAYERS': config.layers.wmsLayers[i]['name'], 'TILED': true},
        serverType: 'geoserver',
        transition: 0,
      }),
      visible: config.layers.wmsLayers[i]['initialVisible'],
    })
    map.addLayer(wmsLayer)
  }

  // Add vector layer (Location pin)
  marker = new Feature({
    type: 'icon',
    geometry: new Point([0,0]),
    
  });
  const vectorLayer = new VectorLayer({
    source: new VectorSource({
      features: [marker],
    }),
    style: new Style({
      image: new Icon({
        anchor: [0.5, 1],
        src: locationPin
      })
    }),
    name: "marker",
    className: "marker" // need this className property, otherwise the whole map will be randomly white after zooming?
  });
  map.addLayer(vectorLayer)

  $(document).ready(function() {

    // Read wms layer list from config and build the layer control
    const html = MakeLayerControl()
    $("#ig-layer-list").append(html);
    $(".list-group-item-checkbox").on("change", OnLayerToggled);

    // Init Address search
    InitAddressSearch('ig-address-search-select');

    // Create event listener for map click event
    map.on('click', OnMapClicked);
    
  });

} else {

  // SHow error message if map param is invalid
  var errorToastElem =document.getElementById('error-toast');
  var errorToast = new bootstrap.Toast(errorToastElem);
  errorToast.show();

}

function getLayerByName(layerName) {
  return map.getLayers().getArray().find((layer) => {
    return  layer.get('name') == layerName
  })
}

function OnMapClicked(e) {
  $("#ig-left-offcanvas").css("display","flex");
  var leftOffcanvas = new bootstrap.Offcanvas($("#ig-left-offcanvas"))
  leftOffcanvas.show()
  marker.setGeometry(new Point(e.coordinate))
  RenderInfoForCoords(e.coordinate, false)
}

function OnBasemapSelected(e) {
  
  const id = $(e.target).attr("id");
  const codes = id.split("_");

  if (codes.length == 2) {

    const basemapId = parseInt(codes[1]);

    for (let i=0; i<config.layers.baseLayers.length; i++) {

      const layer = getLayerByName(config.layers.baseLayers[i]['name']);
      const isVisible =  (i === basemapId);

      layer.setVisible(isVisible);
      const thumbnail = isVisible ? "iconChecked" : "icon";
      const thumbnailUrl =  assets[config.layers.baseLayers[i][thumbnail]].default;
      $("#basemap_"+i).attr("src", thumbnailUrl)
      
    }
  }
  
}

function OnLayerToggled() {
  
  const layerName = $(this).data("layer");
  var layer = getLayerByName(layerName);
  layer.setVisible(!layer.getVisible());

}

function OnAddressSelected(e) {

  var coordStr = $('#ig-address-search-select').val();
  var coords = coordStr.split(",").map(function(v){
    return parseFloat(v)
  });
  $("#map-info").empty();
  ShowLoadingFeatureInfo();

  RenderInfoForCoords(coords, true);
    
  map.getView().setCenter(coords);
  marker.setGeometry(new Point(coords));

}

function RenderInfoForCoords(coordinates, addressSearch=false) {

  ShowLoadingFeatureInfo();

  // Reset
  $("#map-info").empty();
  if (!addressSearch) {
    $("#ig-address-search-select").val("").trigger("change");
  }

  // Send query
  var url = GetSearchPropertiesUrl(coordinates, addressSearch);
  
  if (isQuerying) {
    queryXhr.abort();
  }
  
  isQuerying = true;

  queryXhr = $.getJSON(url , function(e) {

    isQuerying = false;

    HideLoadingFeatureInfo();

    var html = "";

    if (e.status == "Success") {

      e.data.forEach(function(v){
        if (parseInt(v.pid) > 0) {
          html += MakeMapInfoCard(v)
        }
      })
      
      if (html) {
        // Prepend X, Y info box
        var msg = `X: ${coordinates[0].toFixed(5)}, Y:  ${coordinates[1].toFixed(5)}`;
        html = MakeAlert(msg, "primary", "fa-location-crosshairs") + html;
      } else {
        // Show warning
        var msg = 'No properties found at this location.';
        html = MakeAlert(msg, "warning", "fa-circle-exclamation");
      }

    } else {

      var msg = 'An error has occurred. Please contact <a href="mailto:support@insightgis.com.au">support@insightgis.com.au</a> if the issue persists.';
      html = MakeAlert(msg, "danger", "fa-circle-exclamation");

    }

    $("#map-info").empty().append(html);

  });

}

function GetSearchPropertiesUrl(coordinates, addressSearch=false) {
  
  let url = "";

  if (addressSearch) {

    url += queryUrl + "?ACT=get_properties_from_coords&crs=EPSG:3857&x=" + Math.round(coordinates[0]) + "&y=" + Math.round(coordinates[1]);
    
  } else {

    var pixel = map.getPixelFromCoordinate(coordinates);
    var size = map.getSize();
    var extent  = map.getView().calculateExtent(size);

    url += queryUrl + "?ACT=get_properties_from_pixel&crs=EPSG:3857&x=" + Math.round(pixel[0]) + "&y=" + Math.round(pixel[1]) + "&bbox=" +  extent.join(",") + "&width=" + size[0] + "&height=" + size[1];

  }

  return url;

}

function InitAddressSearch(elemId) {
  
  $("#"+elemId).select2({
    theme: 'bootstrap-5',
    dropdownParent: $('#ig-left-offcanvas'),
    width: '100%',
    placeholder: 'Search for an address...',
    minimumInputLength: 3,
    language: {
      inputTooShort: function(args) {
          return "";
      }
    },
    ajax: {
      delay: config.addressSearch.delay,
      dataType: 'json',
      url: function (params) {
        var searchTerm = params.term.toUpperCase();
        var query = config.addressSearch.queryTemplate.replaceAll("[SEARCH_TERM]", searchTerm);
        return config.addressSearch.url + encodeURIComponent(query) ;
      },
      processResults: function (data) {
        var features = data.features;
        var results = features.map(function(v) {
          var id = v.geometry.x + "," + v.geometry.y;
          return {"id": id, "text": v.attributes.ADDRESS}
        })
        return {
          results: results
        };
      }
    }
  });
  $("#"+elemId).on("select2:select", OnAddressSelected);
}

function MakeLayerControl() {

  var html = "";

  for (var i=0; i<config.layers.wmsLayers.length; i++) {

    const currentLayer = config.layers.wmsLayers[i];
    
    html += `<li class="list-group-item d-flex justify-content-between align-items-start">
                    <label class="form-check-label" for="flexCheckDefault">`
                      + currentLayer.displayName +
                    `</label>
                    <input class="form-check-input list-group-item-checkbox me-1" data-layer="${currentLayer.name}" type="checkbox" value="" ` + (currentLayer.initialVisible?"checked":"") + `>
                  </li>`;
    
  }

  return html;
}

function MakeBaseMapControl() {

  var html = "";
  for (let i=0; i<config.layers.baseLayers.length; i++) {
    const currentLayer = config.layers.baseLayers[i];
    const thumbnailKey = currentLayer.initialVisible ? currentLayer.iconChecked : currentLayer.icon;
    const thumbnailUrl =  assets[thumbnailKey].default;
    html += `<div class="col col-xs-4 basemap-option-container">
                <img id="basemap_${i}" src="${thumbnailUrl}">
                <label>${currentLayer.displayName}</label>
              </div>`;
  } 
  return html;
}

function MakeMapInfoCard(prop) {
  var html = `<div class="card map-info-card mb-2">
                <div class="card-body">
                  <h5 class="card-title d-flex flex-row" style="font-size:16px;">
                    <i class="fa-solid fa-lg fa-location-dot me-2" style="line-height:1em;"></i>
                    <span>${prop.prop_add1} ${prop.prop_add2}</span>
                  </h5>
                  <table>
                    <tr class="align-top"><th class="pb-1">Certificate Title:</th><td class="ps-3 pb-1">${prop.volume}/${prop.folio}</td></tr>
                    <tr class="align-top"><th class="pb-1">PID:</th><td class="ps-3 pb-1">${prop.pid}</td></tr>
                    <tr class="align-top"><th class="pb-1">Tenure:</th><td class="ps-3 pb-1">${prop.tenure_ty}</td></tr>
                  </table>
                  <a href="${queryUrl}?ACT=report&cid=${prop.cid}" target="_blank" class="btn btn-primary mt-2"><i class="fa-solid fa-arrow-up-right-from-square me-2"></i>View Report</a>
                </div>
              </div>`
  return html;
}

function MakeAlert(msg, cls="primary", icon="fa-circle-info") {

  var html = `<div class="alert alert-${cls}" role="alert">
                <i class="fa-solid fa-lg ${icon} me-1"></i>
                ${msg}
              </div>`
  return html;
}

function ShowLoadingFeatureInfo() {
 
  $("#map-info-wrapper #spinner-wrapper").removeClass("d-none");
}

function HideLoadingFeatureInfo() {
  
  $("#map-info-wrapper #spinner-wrapper").addClass("d-none");
}

// code for finding the initial x, y 
// map.on('pointerdrag', function() { 
//   let centerPosArr = map.getView().getCenter();
//   console.log(centerPosArr);
// });