const config = {
  map: {
      initialX: 16379862.912018392,
      initialY: -5076253.574444425,
      initialZoom: 14
  },
  layers: {
    baseLayers: [
      {
        name: 'Basemaps_Topographic',
        displayName: 'Topographic',
        url: 'https://services.thelist.tas.gov.au/arcgis/rest/services/Basemaps/Topographic/MapServer/WMTS',
        initialVisible: true,
        icon: '/assets/img/topographic.png',
        iconChecked: '/assets/img/topographic_checked.png'
      },
      {
        name: 'Basemaps_TopographicGrayScale',
        displayName: 'Topographic Gray',
        url: 'https://services.thelist.tas.gov.au/arcgis/rest/services/Basemaps/TopographicGrayScale/MapServer/WMTS',
        initialVisible: false,
        icon: '/assets/img/topographic_gray.png',
        iconChecked: '/assets/img/topographic_gray_checked.png'
      },
      {
        name: 'Basemaps_Orthophoto',
        displayName: 'Orthophoto',
        url: 'https://services.thelist.tas.gov.au/arcgis/rest/services/Basemaps/Orthophoto/MapServer/WMTS',
        initialVisible: false,
        icon: '/assets/img/orthophoto.png',
        iconChecked: '/assets/img/orthophoto_checked.png'
      },
    ],
    wmsLayers: [
      {
        name: 'rem:lcc_rem',
        displayName: "Regional Ecosystem Model",
        url: 'https://maps.insightgis.com.au/geoserver/rem/wms',
        initialVisible: true
      },
      {
        name: 'rem:lcc_parcels',
        displayName: "Properties",
        url: 'https://maps.insightgis.com.au/geoserver/rem/wms',
        initialVisible: true
      },
      {
        name: 'rem:lcc_lga',
        displayName: "LCC Boundary",
        url: 'https://maps.insightgis.com.au/geoserver/rem/wms',
        initialVisible: true
      }
    ]
  },
  addressSearch: {
    url: 'https://services.thelist.tas.gov.au/arcgis/rest/services/Public/SearchService/MapServer/7/query?resultRecordCount=10&orderByFields=STREET&f=json&where=',
    queryTemplate: "ADDRESS like '%[SEARCH_TERM]%' or PROPERTY_NAME like '%[SEARCH_TERM]%'",
    delay: 500
  }
}

export default config;